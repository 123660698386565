import { t } from "@lingui/core/macro";
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
  IAttachmentTransformService,
  ImageType,
} from '@integration-frontends/integration/core/model';
import { AttachmentActionContainerBaseProps } from '@integration-frontends/integration/ui';
import { default as promiseRetry } from 'promise-retry';
import React, { useCallback, useState } from 'react';
import iconFileSvg from '../../../../../assets/file.svg';
import { createIcon } from '../../../../../libs/common/ui/src/lib/icons/create-icon';

export function CopyAttachmentActionContainer({
  selectedAttachments,
  render,
}: AttachmentActionContainerBaseProps) {
  const COPY_LABEL = t`Copy PNG`;
  const COPY_LOADING_LABEL = t`Copying...`;
  const COPY_SUCCESS_LABEL = t`Copied`;
  const COPY_FAILURE_LABEL = t`Copy Failed`;

  const id = 'ATTACHMENT_ACTION_COPY';
  const [label, setLabel] = useState<string>(t`Copy PNG`);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const Icon = createIcon(<img src={iconFileSvg} alt="Attach to sheet" />);
  const transformService = DI_CONTAINER.get<IAttachmentTransformService>(
    ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
  );

  const onClick = useCallback(async () => {
    const attachmentAsPng = await transformService.transform(selectedAttachments[0], {
      mediaType: ImageType.Png,
    });

    setLoading(true);
    setLabel(COPY_LOADING_LABEL);

    try {
      await copyImageToClipboard(attachmentAsPng?.url);
      setLoading(false);
      setSuccess(true);
      setLabel(COPY_SUCCESS_LABEL);
    } catch (e) {
      setLoading(false);
      setSuccess(false);
      setLabel(COPY_FAILURE_LABEL);
    }

    setTimeout(() => {
      setLabel(COPY_LABEL);
    }, 2000);
  }, [selectedAttachments]);

  return render({
    id,
    Icon,
    label,
    loading,
    success,
    enabled: true,
    onClick,
    ariaLabel: COPY_LABEL,
  });
}

function copyImageToClipboard(url: string) {
  const img = new Image();
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  img.crossOrigin = '';
  img.src = url;

  return new Promise<void>((resolve, reject) => {
    img.onload = function () {
      c.width = img.naturalWidth;
      c.height = img.naturalHeight;
      ctx.drawImage(img, 0, 0);
      c.toBlob(
        async (blob) => {
          // here the image is a blob
          try {
            await navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]);
            resolve();
          } catch (e) {
            reject();
          }
        },
        'image/png',
        0.75,
      );
    };
  });
}
