import 'reflect-metadata'; //needs to be first import
import './init'; // needs to be second import
import { getI18nInstance } from '@integration-frontends/core';
import { RootContainer } from '@integration-frontends/core/ui';
// eslint-disable-next-line @nx/enforce-module-boundaries
import 'libs/common/ui/src/lib/styles/tailwind.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import App from './app/app';

ReactDOM.render(
  <RootContainer>
    <I18nextProvider i18n={getI18nInstance()}>
      <App />
    </I18nextProvider>
  </RootContainer>,
  document.getElementById('root') as HTMLElement,
);
