import { Trans } from "@lingui/react/macro";
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';
import './drop-zone.scss';

export interface BFDropZoneProps {
  className?: string;
  onDrop: (acceptedFiles: File[]) => void;
  style?: HTMLAttributes<any>['style'];
}

export function BFDropZone({ className, onDrop, style }: BFDropZoneProps) {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop });

  return (
    <div
      className={classNames('drop-zone', className)}
      {...getRootProps()}
      style={{ minHeight: 100, ...style }}
    >
      <input {...getInputProps()} />
      <span className="drop-zone-text">
        <Trans>DRAG FILE(S) HERE OR CLICK TO BROWSE</Trans>
      </span>
    </div>
  );
}