import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { BFAnchor, BFButton, ButtonSize, ButtonType } from '@integration-frontends/common/ui';
import {
  backToHome,
  UPLOAD_ASSETS_OPTIONS_TOKEN,
  uploadAssetsSelectors,
  uploadMore,
} from '@integration-frontends/integration/core/application';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentBody } from './common';
import { DI_CONTAINER } from '@integration-frontends/core';
import { NotificationsContainer } from '../common/notifications/notifications';
import './upload-success.scss';
import { IconUploadAssetSuccess } from '@integration-frontends/common/ui/icons/icon-upload-asset-success';
import { sendSegmentAction } from '@integration-frontends/common/analytics';

export function UploadSuccess() {
  const dispatch = useDispatch();
  const selectedContainer = useSelector(uploadAssetsSelectors.selectedContainer);
  const selectedSection = useSelector(uploadAssetsSelectors.selectedSection);
  const shareLink = useSelector(uploadAssetsSelectors.shareLink);
  const asset = useSelector(uploadAssetsSelectors.uploadedAsset);
  const { showAddMore } = DI_CONTAINER.get(UPLOAD_ASSETS_OPTIONS_TOKEN);

  const visitInBrandfolderClicked = useCallback(() => {
    dispatch(sendSegmentAction({ event: 'openInBrandfolder' }));
    window.open(shareLink, '_blank');
  }, [asset, selectedContainer]);

  return (
    <ContentBody
      className="upload-to-brandfolder-container"
      data-testid="asset-upload-success-container"
    >
      <div className="icon-upload-asset-success-container">
        <IconUploadAssetSuccess style={{ width: '340px', height: '190px' }} />
      </div>
      <span className="upload-to-brandfolder-title">
        <Trans>Uploaded to Brandfolder!</Trans>
      </span>
      <section className="upload-to-brandfolder-message-container">
        <p className="upload-to-brandfolder-message">
          <Trans>You've successfully uploaded this asset to</Trans>
        </p>
        <p className="upload-to-brandfolder-message upload-to-brandfolder-message-bold">
          {` ${selectedSection.name} ${t`in`} ${selectedContainer?.name || ''}`}
        </p>
        <p className="upload-to-brandfolder-message">
          <Trans>. Your settings are saved for the next time you upload to Brandfolder.</Trans>
        </p>
      </section>
      <span className="upload-to-brandfolder-share-link-label">
        <Trans>Share link</Trans>
      </span>
      <div className="upload-to-brandfolder-share-link-container">
        <span
          className="upload-to-brandfolder-share-link"
          id="upload-to-brandfolder-share-link"
          data-testid="upload-to-brandfolder-share-link"
        >
          {shareLink ? shareLink : <Trans>Unable to generate link</Trans>}
        </span>
      </div>
      <span className="upload-to-brandfolder-divider" />
      <div className="upload-to-brandfolder-footer">
        {showAddMore && (
          <BFAnchor style={{ marginBottom: 63 }} onClick={() => dispatch(uploadMore())}>
            <Trans>Want to upload more assets?</Trans>
          </BFAnchor>
        )}
        <BFButton
          size={ButtonSize.Small}
          buttonType={ButtonType.Secondary}
          onClick={() => dispatch(backToHome())}
        >
          <Trans>Close</Trans>
        </BFButton>
        <BFButton
          size={ButtonSize.Small}
          className="open-in-brandfolder-button"
          onClick={visitInBrandfolderClicked}
        >
          <Trans>Open in Brandfolder</Trans>
        </BFButton>
      </div>
      <NotificationsContainer location={'upload-success'} placement="above" />
    </ContentBody>
  );
}
